import { Link } from "gatsby"
import React, { useState, useCallback } from "react"
import { FaGithub, FaBook } from 'react-icons/fa'
import logo from '../images/icon.svg'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Nav = ({ title }) => {

  const [isNavVisible, setNavVisible] = useState(false)
  const onNavToggleClick = useCallback(() => setNavVisible(!isNavVisible), [isNavVisible, setNavVisible])

  return (
    <nav className="header">
      <Link to="/" className="logo">
        <img className="logo__icon" alt="Serverless IDE" width="36" src={logo} />
        <h1>
          Serverless IDE{' '}
          <span className="logo__thin">
            {title}
          </span>
        </h1>
      </Link>
      <ul className={`menu ${isNavVisible ? 'responsive' : ''}`}>
        <div className={`menu__item toggle ${isNavVisible ? 'open' : ''}`} onClick={onNavToggleClick}><span></span></div>
        <li className="menu__item">
          <OutboundLink className="link link--dark" href="https://marketplace.visualstudio.com/items?itemName=ThreadHeap.serverless-ide-vscode" target="blank">
            VSCode Marketplace
          </OutboundLink>
        </li>
        <li className="menu__item">
          <Link className="link link--dark" to="/blog">
            <FaBook className="link__icon" />
            Blog
          </Link>
        </li>
        <li className="menu__item">
          <OutboundLink className="link link--dark" href="https://github.com/threadheap/serverless-ide-vscode" target="blank">
            <FaGithub className="link__icon" /> GitHub
          </OutboundLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
