import React from "react"
import { Link } from 'gatsby';

const Footer = () => (
    <footer className="footer">
        <a href="mailto:hello@serverless-ide.com" className="link link--light">Contact us</a>
        <a href="https://pavel207.typeform.com/to/jIICkA" target="blank" className="link link--light">Newsletter</a>
        <Link to="/blog" className="link link--light">Blog</Link>
        <Link to="/privacy" className="link link--light">Privacy policy</Link>
        <br />
        © 2019 ThreadHeap
    </footer>
)

export default Footer
